<script lang="ts" setup>
import TwitterSvg from '@/assets/svg/twitter.svg?component'
import LinkedInSvg from '@/assets/svg/linkedin.svg?component'
import FacebookSvg from '@/assets/svg/facebook.svg?component'
import InstagramSvg from '@/assets/svg/instagram.svg?component'
import VimeoSvg from '@/assets/svg/vimeo.svg?component'
import YouTubeSvg from '@/assets/svg/youtube.svg?component'
import RssSvg from '@/assets/svg/rss.svg?component'
import SitemapSvg from '@/assets/svg/sitemap.svg?component'
import consts from '@/consts'
const { locale } = useI18n()

const props = defineProps({
  colorMode: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
  iconsToHide: {
    type: Array as PropType<SocialUrlObjKey[]>,
    default: () => [],
  },
})
</script>

<template>
  <div class="social-icon-links" :class="`social-icon-links--${props.colorMode}`">
    <template v-for="({ svgIconName, url, ariaName }, key) in consts.socialUrls" :key="svgIconName">
      <a
        v-if="!props.iconsToHide.includes(key)"
        class="social-icon-links__link"
        :href="svgIconName === 'RssSvg' ? `/${locale}${url}` : url"
        target="_blank"
        rel="noopener noreferrer"
        :aria-label="ariaName"
        :title="ariaName"
      >
        <TwitterSvg v-if="svgIconName === 'TwitterSvg'" />
        <LinkedInSvg v-if="svgIconName === 'LinkedInSvg'" />
        <FacebookSvg v-if="svgIconName === 'FacebookSvg'" />
        <InstagramSvg v-if="svgIconName === 'InstagramSvg'" />
        <VimeoSvg v-if="svgIconName === 'VimeoSvg'" />
        <YouTubeSvg v-if="svgIconName === 'YouTubeSvg'" />
        <RssSvg v-if="svgIconName === 'RssSvg'" />
        <SitemapSvg v-if="svgIconName === 'SitemapSvg'" />
      </a>
    </template>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';

.social-icon-links {
  display: flex;
  align-items: center;
  .fluid(gap, 20, 40);

  &__link {
    display: block;
    width: 28px;
    .fluid(width, 16, 28);

    svg path {
      transition: fill 0.15s;
      fill: #fff;

      .social-icon-links--dark & {
        fill: var(--grey-1);
      }
    }

    &:hover {
      .supports-hover({
        svg path {
          fill:  var(--yellow-1);

          .social-icon-links--dark & {
            fill: var(--grey-3);
          }
        }
      });
    }
  }
}
</style>
